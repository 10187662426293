/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FormSelect,
  CardBody,
  CardFooter,
  ModalBody,
  Form,
  Modal,
  FormTextarea,
  FormInput,
  ButtonGroup,
  Badge,
  Button,
  Nav,
} from "shards-react";

import * as moment from 'moment';

import { Store } from "../flux";

import PageTitle from "../components/common/PageTitle";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

import './cleanings_style.css';

class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: Store.getKeyServices(),
      keyServiceRangeFocus: false,
      keyServiceRangeStart: moment(),
      keyServiceRangeEnd: moment().endOf('week'),
      selectedStatus: 'all',
      newKeyServiceDialogOpen: false,
      newKeyService_date: moment(),
      newKeyService_cleaning_time: '15:00',
      newKeyService_property: null,
      newKeyService_description: '',
      properties: Store.getProperties(),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    Store.fetchProperties();
    Store.fetchKeyServices(moment(), moment().add(1, 'month').endOf('month'));
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      events: Store.getKeyServices(),
      properties: Store.getProperties(),
    });
    console.log(this.state);
  }

  openNewKeyServiceDialog = () => {
    this.setState({
      newKeyServiceDialogOpen: true
    });
  }
  closeNewKeyServdeDialog = () => {
    this.setState({
      newKeyServiceDialogOpen: false
    });
  }

  setKeyServiceRange = (sDate, eDate) => {
    this.setState({ keyServiceRangeStart: sDate, keyServiceRangeEnd: eDate })
    Store.fetchKeyServices(moment(sDate).format(), moment(eDate).format());
  }

  setSelectedStatus = (status) => {
    this.setState({
      selectedStatus: status
    })
  }

  getStyleByStatus = (status) => {
    switch (status) {
      case 'pending':
        return {
          style: 'warning',
          text: 'Approve',
          statusText: 'Vireillä',
          nextStatus: 'approved'
        }
        break;
      case 'approved':
        return {
          style: 'primary',
          text: 'Start',
          statusText: 'Vastaanotettu',
          nextStatus: 'started'
        }
        break;
      case 'started':
        return {
          style: 'success',
          text: 'Done',
          statusText: 'Käynnissä',
          nextStatus: 'done'
        }
        break;
      case 'done':
        return {
          style: 'secondary',
          text: 'Done',
          statusText: 'Suoritettu',
          nextStatus: 'pending'
        }
        break;
      default:
      // code block
    }
  }

  selectPropertyKey = (event) => {
    this.setState({ newKeyService_property: event.target.value })
  }

  changeStatus = (id, status) => {
    Store.changeStatus(id, status, () => {
      Store.fetchEvents(this.state.cleaningsRangeStart, this.state.cleaningsRangeEnd);
    });
  }
  closeNewCleaningDialog = () => {
    this.setState({
      newCleaningDialogOpen: false
    });
  }

  createKeyService = (event) => {
    event.preventDefault();
    Store.createKeyService(
      this.state.newKeyService_date,
      this.state.newKeyService_cleaning_time,
      this.state.newKeyService_property,
      this.state.newKeyService_description,
      (status) => {
        if (status) {
          this.setState({
            newKeyServiceDialogOpen: false
          });
          Store.fetchKeyServices(moment(this.state.keyServiceRangeStart).format(), moment(this.state.keyServiceRangeEnd).format());

        } else {
          alert('Error creating event');
        }
      }
    )
  }

  render() {
    const {
      PostsListOne,
      events
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4 justify-content-between align-items-center">
          <PageTitle sm="4" title="" subtitle="Cleanings" className="text-sm-left" />
        </Row>
        {/* First Row of Posts */}
        <Row>
          <Col lg="12" md="12" sm="12" className="mb-4">
            <Card small className="card-post card-post--1" >
              <CardBody>
                <div className="cleanings-filters-container">
                  <div className="cleanings-dateranhe-picker">
                    <span className="cleanings-dateranhe-picker-header-text">Period</span>
                    <DateRangePicker
                      block
                      startDate={this.state.keyServiceRangeStart} // momentPropTypes.momentObj or null,
                      startDateId="879768sdad768678" // PropTypes.string.isRequired,
                      endDate={this.state.keyServiceRangeEnd} // momentPropTypes.momentObj or null,
                      endDateId="213sada213422434" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => this.setKeyServiceRange(startDate, endDate)} // PropTypes.func.isRequired,
                      focusedInput={this.state.keyServiceRangeFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ keyServiceRangeFocus: focusedInput })} // PropTypes.func.isRequired,
                      firstDayOfWeek={1}
                      hideKeyboardShortcutsPanel
                      displayFormat="DD/MM/YYYY"
                      enableOutsideDays
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                    />
                  </div>
                  <div className="cleanings-filters-status-container">
                    <span className="cleanings-dateranhe-picker-header-text">Status</span>
                    <div className="cleanings-status-items-container">
                      <div
                        className={this.state.selectedStatus === 'all' ? "cleanings-status-item-container selected" : "cleanings-status-item-container"}
                        onClick={() => this.setSelectedStatus('all')}
                      >
                        <span className="cleanings-status-item-container-text">Kaikki</span>
                      </div>
                      <div
                        className={this.state.selectedStatus === 'pending' ? "cleanings-status-item-container selected" : "cleanings-status-item-container"}
                        onClick={() => this.setSelectedStatus('pending')}
                      >
                        <span className="cleanings-status-item-container-text">Vireillä</span>
                      </div>
                      <div
                        className={this.state.selectedStatus === 'approved' ? "cleanings-status-item-container selected" : "cleanings-status-item-container"}
                        onClick={() => this.setSelectedStatus('approved')}
                      >
                        <span className="cleanings-status-item-container-text">Vastaanotettu</span>
                      </div>
                      <div
                        className={this.state.selectedStatus === 'started' ? "cleanings-status-item-container selected" : "cleanings-status-item-container"}
                        onClick={() => this.setSelectedStatus('started')}
                      >
                        <span className="cleanings-status-item-container-text">Käynnissä</span>
                      </div>
                      <div
                        className={this.state.selectedStatus === 'done' ? "cleanings-status-item-container selected" : "cleanings-status-item-container"}
                        onClick={() => this.setSelectedStatus('done')}
                      >
                        <span className="cleanings-status-item-container-text">Suoritettu</span>
                      </div>
                    </div>
                  </div>
                  <div className="cleamings-new-cleaning-container">
                    <Button onClick={() => { this.setState({ newKeyServiceDialogOpen: true }) }}>Add</Button>
                  </div>
                </div>
                <table className="table mb-0 table-striped table-hover cleanings-table-desktop">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Päivämäärä
                        </th>
                      <th scope="col" className="border-0">
                        Paikka
                        </th>
                      <th scope="col" className="border-0">
                        Delivery Time
                        </th>
                      <th scope="col" className="border-0">
                        Information
                        </th>
                      <th scope="col" className="border-0">
                        Status
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.filter(e => e.status === this.state.selectedStatus || this.state.selectedStatus === 'all').map((event, idx) => (
                      <tr>
                        <td>
                          {moment(event.date).format('dddd, MMMM Do YYYY')}
                        </td>
                        <td>
                          {event.property.name}
                        </td>
                        <td>
                          {event.delivery_time}
                        </td>
                        <td style={{maxWidth: 300}}>
                          {event.description}
                        </td>
                        <td>
                          <Badge
                            pill
                            className={`bg-${this.getStyleByStatus(event.status).style}`}
                          >
                            {this.getStyleByStatus(event.status).statusText}
                          </Badge>
                        </td>                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row className="cleanings-mobile-view">
          {1 == 1 && events.map((event, idx) => (
            <Col lg="12" md="12" sm="12" className="mb-4" key={event.id}>
              <Card small className="card-post card-post--1" bg="secondary">
                <CardBody>
                  <Badge
                    pill
                    className={`card-post__category bg-${this.getStyleByStatus(event.status).style}`}
                  >
                    {event.status}
                  </Badge>
                  <h5 className="card-title" style={{ marginTop: 15 }}>
                    <a href="#" className="text-fiord-blue">
                      {moment(event.date).format('dddd, MMMM Do YYYY')}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3" >{event.information}</p>
                  <span className="text-muted">{event.property.name}</span>
                  {event.description !== '' && <div style={{ marginTop: 20 }}>
                    <span style={{ fontWeight: '700', color: '#555' }}>Description</span>
                    <hr style={{ margin: 5 }} />
                    <span style={{ fontWeight: '300', color: '#555', fontSize: 12 }}>{event.description}</span>
                  </div>}
                  {event.cleaner && <div style={{ marginTop: 30 }}>
                    <span style={{ fontWeight: '700', color: '#555' }}>Approved By</span>
                    <hr style={{ margin: 5 }} />
                    <span style={{ fontWeight: '300', color: '#555', fontSize: 12 }}>{event.cleaner.name}</span>
                  </div>}
                  {event.started_at && <div style={{ marginTop: 10 }}>
                    <span style={{ fontWeight: '700', color: '#555' }}>Started at</span>
                    <hr style={{ margin: 5 }} />
                    <span style={{ fontWeight: '300', color: '#555', fontSize: 12 }}>{moment(event.started_at).format('MMMM Do YYYY, HH:mm')}</span>
                  </div>}
                  {event.finished_at && <div style={{ marginTop: 10 }}>
                    <span style={{ fontWeight: '700', color: '#555' }}>Finished at</span>
                    <hr style={{ margin: 5 }} />
                    <span style={{ fontWeight: '300', color: '#555', fontSize: 12 }}>{moment(event.finished_at).format('MMMM Do YYYY, HH:mm')}</span>
                  </div>}

                </CardBody>
                {event.status !== 'done' &&
                  <div className="card-button-container">
                    <button
                      type="button"

                      className={`btn btn-${this.getStyleByStatus(event.status).style} btn-rounded-container`}
                      onClick={() => this.changeStatus(event.id, this.getStyleByStatus(event.status).nextStatus)}
                    >
                      {this.getStyleByStatus(event.status).text}
                    </button>
                  </div>
                }
              </Card>
            </Col>
          ))}
        </Row>
        <Modal open={this.state.newKeyServiceDialogOpen} toggle={this.closeNewKeyServdeDialog} backdropClassName="add-cleaning-backdrop" className="add-cleaning-modal">
          <ModalBody>
            <Form onSubmit={this.createKeyService}>
              <div className="add-cleaning-calendar-container">
                <div className="add-cleaning-calendar-header-container">
                  <p className="add-cleaning-calendar-header-text">Päivämäärä</p>
                </div>
                <SingleDatePicker
                  date={this.state.newKeyService_date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ newKeyService_date: date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id2" // PropTypes.string.isRequired,
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel
                  displayFormat="DD/MM/YYYY"
                />
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Aika</p>
                  </div>
                  <FormInput placeholder="21:00" type="text" value={this.state.newKeyService_cleaning_time} onChange={(event) => this.setState({ newKeyService_cleaning_time: event.target.value })} />
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Kohde</p>
                  </div>
                  <FormSelect value={this.state.newKeyService_property} onChange={this.selectPropertyKey}>
                    <option value={null}></option>
                    {this.state.properties.map(property => (
                      <option value={property.id}>{property.name}</option>
                    ))}
                  </FormSelect>
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Huomautukset</p>
                  </div>
                  <FormTextarea size="xs" onChange={(event) => this.setState({ newKeyService_description: event.target.value })} value={this.state.newKeyService_description} />
                </div>
              </div>


              <div className="add-cleaning-add-button-container">
                <Button className="add-cleaning-add-button" type="submit">Lisää</Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
       </Container>
    );
  }
}

export default BlogPosts;
