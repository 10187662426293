/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
} from "shards-react";

import * as moment from 'moment';

import { Store } from "../flux";


class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.onChange = this.onChange.bind(this);
  }
 
  componentWillMount() {    
    Store.addChangeListener(this.onChange);
    //Store.fetchEvents();
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
  
  onChange() {
    this.setState({
      ...this.state,
      events: Store.getEvents(),
    });
    console.log(this.state);
  }
  
  setEmail = (event) => {
    this.setState({email: event.target.value});
  }

  setPassword = (event) => {
    this.setState({password: event.target.value});
  }

  login = (event) => {
    event.preventDefault();
    Store.login(this.state.email, this.state.password, () => this.props.history.push('/dashboard'));
    
  } 

  render() {
    const {
      email,
      password
    } = this.state;
    
    return (
      <Container fluid className="main-content-container px-4" style={{height: '100vh'}}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4 justify-content-center align-items-center" style={{height: '100%'}}>          
          <Card small>
            <CardHeader className="border-bottom text-center">
              <h6 className="m-0">Login</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form onSubmit={this.login}>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label htmlFor="feEmailAddress">Email</label>
                          <FormInput
                            id="feEmailAddress"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={this.setEmail}
                          />
                        </Col>
                        <Col md="12">
                          <label htmlFor="fePassword">Password</label>
                          <FormInput
                            id="fePassword"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={this.setPassword}
                          />
                        </Col>
                      </Row>  
                      <Row>
                        <Col>                    
                          <div style={{display: 'flex', marginTop: 20, justifyContent: 'center'}}>
                            <Button type="submit">Login</Button>
                          </div>
                        </Col>                    
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default BlogPosts;
