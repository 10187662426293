import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, PlainLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Overview";
import UserProfile from "./views/UserProfile";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Cleanings from "./views/Cleanings";
import KeysService from "./views/KeyService";
import Properties from "./views/Properties";
import Login from "./views/Login";
//import Landing from "./views/Landing";
import Users from "./views/Users";
import Rovaniemi from "./views/Rovaniemi";
import Proposal from "./views/Proposal/";

export default [
  // {
  //   path: "/",
  //   exact: true,
  //   layout: PlainLayout,
  //   component: Landing
  // },
  {
    path: "/",
    exact: true,
    layout: PlainLayout,
    component: Rovaniemi
  },
  {
    path: "/rovaniemi",
    layout: PlainLayout,
    component: Rovaniemi
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  // {
  //   path: "/landing",
  //   layout: PlainLayout,
  //   component: Landing
  // },
  // {
  //   path: "/user-profile",
  //   layout: DefaultLayout,
  //   component: UserProfile
  // },
  // {
  //   path: "/add-new-plan",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  {
    path: "/properties",
    layout: DefaultLayout,
    component: Properties
  },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  {
    path: "/login",
    layout: PlainLayout,
    component: Login
  },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/cleanings",
    layout: DefaultLayout,
    component: Cleanings
  },
  {
    path: "/keys",
    layout: DefaultLayout,
    component: KeysService
  },
  {
    path: "/proposal",
    layout: PlainLayout,
    component: Proposal
  }
];
