import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";

import * as moment from 'moment';

import { Store } from "../../../../flux";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      cleanings: Store.getEvents(),
      user: Store.getUser(),
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  componentWillMount() {    
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange = () => {
    this.setState({
      ...this.state,
      cleanings: Store.getEvents(),
      user: Store.getUser(),
    });
    console.log(this.state);
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {

    const notificationCount = this.state.cleanings.filter(cleaning => cleaning.createdAt > this.state.user.last_login).length;

    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {notificationCount > 0 && (
              <Badge pill theme="danger">
                {notificationCount}
              </Badge>
            )}
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
        >
          {this.state.cleanings.map(cleaning => {
            
            if(cleaning.createdAt > this.state.user.last_login) {
              return (
                <DropdownItem>
                  <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                      <i className="material-icons">&#xE6E1;</i>
                    </div>
                  </div>
                  <div className="notification__content">
                    <span className="notification__category">Cleanings</span>
                    <p>
                      {`Cleaning on ${moment(cleaning.date).format('dddd, MMMM Do YYYY')} ${cleaning.createdAt === cleaning.updatedAt ? 'added' : 'updated'}`}
                    </p>
                  </div>
                </DropdownItem>      
              )
            }
          })}
          <DropdownItem className="notification__all text-center">
            View all Notifications
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
