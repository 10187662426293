/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";

import { Store } from "../flux";

import PageTitle from "../components/common/PageTitle";

import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Store.getUser(),    
    };

    this.onChange = this.onChange.bind(this);
  }
 
  componentWillMount() {    
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
  
  onChange() {
    this.setState({
      ...this.state,
      user: Store.getUser(),
    });    
  }

  render() {
    const {
      user
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="4">
            <UserDetails userDetails={user} />
          </Col>
          <Col lg="8">
            <UserAccountDetails userDetails={user} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BlogPosts;
