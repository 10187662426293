import 'babel-polyfill';
import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import history from './history';


import routes from "./routes";
import withTracker from "./withTracker";

import { Store } from "./flux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

export default () => (
  <Router
    basename={process.env.REACT_APP_BASENAME || ""}
    history={history}
  >
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}            
            component={withTracker(props => {
              return Store.isAuthenticated() || route.path == '/login' || route.path == '/' || route.path == '/rovaniemi' || route.path === '/proposal'  ?
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              :
              <Redirect to={{
                pathname: '/login',               
              }} />
            })}
          />
        );
      })}
    </div>
  </Router>
);
