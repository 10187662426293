/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";

import { Store } from "../flux";

import PageTitle from "../components/common/PageTitle";

class Campuses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: Store.getUsers(),    
    };

    this.onChange = this.onChange.bind(this);
  }
 
  componentWillMount() {    
    Store.addChangeListener(this.onChange);
    Store.fetchUsers();
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {    
    this.setState({
      ...this.state,
      users: Store.getUsers(),
    });
    console.log(this.state);
  }

  render() {
    const {
      users
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4 justify-content-between align-items-center">
          <PageTitle sm="4" title="Campuses" subtitle="" className="text-sm-left" />
          <Button theme="primary" className="mb-2 mr-1 disabled" href="/add-new-user" >
            Add New Campus
          </Button>
        </Row>

        {/* Add Ne Plan Button */}
        <Row noGutters className="page-header py-4 text-right">
         
        </Row>
        {/* First Row of Posts */}
        <Row>         
            <Col lg="12" md="12" sm="12" className="mb-4">
              <Card small className="card-post card-post--1" >
                <CardBody>
                  <table className="table mb-0 table-striped table-hover">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Picture
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Properties
                        </th>
                        <th scope="col" className="border-0">
                          Last Login
                        </th>                       
                      </tr>
                    </thead>
                    <tbody>
                    {users.map((group, idx) => (
                      <tr>                        
                        <td>
                          <div style={{width: 50, height: 50, backgroundColor: '#ddd', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', color: '#fff'}}>
                            {group.name.split(" ").map((n)=>n[0]).join("")}
                          </div>
                        </td>
                        <td title={group.id}>
                          {group.name}
                        </td>                        
                        <td>{group.properties.map(p => (
                            <p>{p.property} - {p.role}</p>
                        ))}</td>                                                
                        <td>{group.last_login}</td>                        
                      </tr>                      
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          
        </Row>
      </Container>
    );
  }
}

export default Campuses;
