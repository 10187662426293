import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

import history from '../history';

import axios from 'axios';

let _store = {
  menuVisible: false,
  navItems: [],
  events: [],
  fetchingEvents: true,
  groups: [],
  plans: [],
  users: [],
  properties: [],
  keyServices: [],
  fetchingKeyServices: true,
  proposal: null,
  paymentToken: null,
  newUser: {
    name: '',
    email: '',
    password: ''
  },
  token: localStorage.getItem('cleaning_platform_user_token') || null,
  betaAccessAlertStatus: localStorage.getItem('cleaning_platform_user_beta_access_request') || false,
  user: JSON.parse(localStorage.getItem('cleaning_platform_user_data')) || {},
  statistics: {
    doneCleanings: {
      totalDuration: 0,
      cleanings: []
    },
    currentCleanings: [],
    approvedCleanings: []
  }
};

class Store extends EventEmitter {
  constructor(props) {
    super(props);

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    // this.fetchEvents = this.fetchEvents.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.FETCH_EVENTS:
        this.fetchEvents();
        break;
      case Constants.FETCH_GROUPS:
        this.fetchEvents();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }
  //User actions 
  //----------------------------------------------------
  isAuthenticated = () => {
    if (_store.token === null) {
      return false;
    } else {
      _store.navItems = getSidebarNavItems(_store.user.role);
      return true;
    }
  }
  getStore = () => {
    return _store;
  }
  login = (email, password, cb) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/auth`,
      headers: { 'Authorization': `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}` }
    })
      .then(response => {
        // handle success
        console.log(response);
        _store.token = response.data.token;
        _store.user = response.data.user;
        _store.navItems = getSidebarNavItems(response.data.user.role);
        this.emit(Constants.CHANGE);
        localStorage.setItem('cleaning_platform_user_token', _store.token);
        localStorage.setItem('cleaning_platform_user_data', JSON.stringify(_store.user));
        cb();
        // history.push('/cleanings');
        // this.context.router.transitionTo('/Some/Path')
        // window.location.reload()

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  //----------------------------------------------------

  // Event actions
  // ----------------------------------------------------
  fetchEvents = (startD, endD,) => {    
    console.log('Fetching...');
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/cleanings?startDate=${new Date(startD)}&endDate=${new Date(endD)}`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        // handle success
        console.log(response);
        _store.events = response.data;
        _store.fetchingEvents = false;
        this.emit(Constants.CHANGE);        
      })
      .catch(function (error) {
        // handle error
        _store.fetchingEvents = false;
        this.emit(Constants.CHANGE); 
        console.log(error);
      })
      .finally(function () {        
        // always executed
      });
  }
  isEventsFetching = () => {
    return _store.fetchingEvents;
  }

  createCleaning = (date, cleaning_time, guests_amount, property, description, cb) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/cleanings/`,
      headers: { 'Authorization': 'Bearer ' + _store.token },
      data: {
        date: date,
        cleaning_time: cleaning_time,
        guests_amount: guests_amount,
        property: property,
        description: description
      }
    })
      .then(response => {
        console.log(response);
        this.emit(Constants.CHANGE);
        cb(true);
      })
      .catch(function (error) {

        cb(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  changeStatus = (id, status, cb) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/cleanings/${id}/status?status=${status}`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        // handle success
        console.log(response);
        cb(true);        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getEvents = () => {
    return _store.events;
  }
  // ----------------------------------------------------


  // Properties actions
  // ----------------------------------------------------
  fetchProperties = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/properties`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        // handle success
        console.log(response);
        _store.properties = response.data;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getProperties = () => {
    return _store.properties;
  }
  // ----------------------------------------------------


  // Dashboard actions
  // ----------------------------------------------------
  fetchStatistics = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/users/statistics`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        // handle success
        console.log(response);
        _store.statistics = response.data;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getStatistics = () => {
    return _store.statistics;
  }
  // ----------------------------------------------------

  //-----------------------------------------------------
  // Landing page actions
  requestBetaAccess = (name, email, cb) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/users/betarequest?name=${name}&&email=${email}`,
    })
      .then(response => {
        console.log(response);
        _store.betaAccessAlertStatus = true;
        localStorage.setItem('cleaning_platform_user_beta_access_request', true);
        this.emit(Constants.CHANGE);
        cb(true);
      })
      .catch(function (error) {
        // handle error
        cb(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getBetaAlertStatus() {
    return _store.betaAccessAlertStatus;
  }

  requestContact = (data, cb) => {
    console.log('requestin contacts')
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/users/contactform`,
      headers: {        
        'Content-Type': 'application/json'
      },
      data: {
        firstName: data.firstName,
        familyName: data.familyName,      
        email: data.email,
        phoneNumber: data.phoneNumber,
        interestedIn: data.intrestedIn,
        agreed: data.agreed        
      }
    })
      .then(response => {
        console.log(response);
        _store.betaAccessAlertStatus = true;
        localStorage.setItem('cleaning_platform_user_beta_access_request', true);
        this.emit(Constants.CHANGE);
        cb(true);
      })
      .catch(function (error) {
        // handle error
        cb(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  createAccount = (name, email, password, token, cb) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/users/`,
      data: {
        name: name,
        password: password,
        email: email,
        token: token,
        role: 'owner'
      }
    })
      .then(response => {
        console.log(response);
        _store.token = response.data.token;
        _store.user = response.data.user;
        this.emit(Constants.CHANGE);
        localStorage.setItem('cleaning_platform_user_token', _store.token);
        cb(true);
      })
      .catch(function (error) {

        cb(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }


  //-----------------------------------------------------

  fetchUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/users`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        console.log(response);
        _store.users = response.data;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }

  setPaymentToken = (token) => {
    console.log(token);
    _store.paymentToken = token;
  }

  setNewName = (name) => {
    _store.newUser.name = name;
  }
  setNewPassword = (password) => {
    _store.newUser.password = password;
  }
  setNewEmail = (email) => {
    _store.newUser.email = email;
  }

  getPaymentToken = () => {
    return _store.paymentToken;
  }

  getUsers() {
    return _store.users;
  }
  //-----------------------------------------------------
  //-----------------------------------------------------
  fetchPlans = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/plans`,
    })
      .then(response => {
        console.log(response);
        _store.plans = response.data;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      })
  }

  getPlans() {
    return _store.plans;
  }
  //-----------------------------------------------------


  //-----------------------------------------------------
  fetchProposal = (id) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/proposals/${id}`,
    })
      .then(response => {
        console.log(response);
        _store.proposal = response.data;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      })
  }

  getProposal() {
    return _store.proposal;
  }
  //-----------------------------------------------------


  // Event actions
  // ----------------------------------------------------
  fetchKeyServices = (startD, endD) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/keys?startDate=${new Date(startD)}&endDate=${new Date(endD)}`,
      headers: { 'Authorization': 'Bearer ' + _store.token }
    })
      .then(response => {
        // handle success
        console.log(response);
        _store.keyServices = response.data;
        _store.fetchingKeyServices = false;
        this.emit(Constants.CHANGE);
      })
      .catch(function (error) {
        // handle error
        _store.fetchingKeyServices = false;
        this.emit(Constants.CHANGE);
        console.log(error);
      })
      .finally(function () {
        // always executed        
      });
  }

  isKeyServicesFetching = () => {
    return _store.fetchingKeyServices;
  }

  createKeyService = (date, delivery_time, property, description, cb) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/keys/`,
      headers: { 'Authorization': 'Bearer ' + _store.token },
      data: {
        date: date,
        delivery_time: delivery_time,        
        property: property,
        description: description
      }
    })
      .then(response => {
        console.log(response);
        this.emit(Constants.CHANGE);
        cb(true);
      })
      .catch(function (error) {

        cb(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getKeyServices = () => {
    return _store.keyServices;
  }
  // ----------------------------------------------------


  getUser() {
    return _store.user;
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
