/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Button,
  Slider,
  Form,
  FormInput,
  Alert,
  Modal,
  ModalBody
} from "shards-react";


import { Store } from "../../flux";

import * as moment from 'moment';

import './style.css';


class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proposal: Store.getProposal(),
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    let query = new URLSearchParams(this.props.location.search);
    const id = query.get("_id");
  //   const reg = new RegExp("([a-f\d]{24})");
  //   console.log(id);
  //  console.log(id.match(reg));
    if(id !== null) {
      Store.fetchProposal(id);
    } else {
      this.props.history.push('/')
    }
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      proposal: Store.getProposal(),
    });
  }

  render() {
    return (
      <div className="proposal-main-container">
        {this.state.proposal !== null &&
        <div className="proposal-sub-container">          
          <div className="header-container">
            <p className="proposal-container-main-header">Cleanday</p>
          </div>         
          <div className="proposal-info-container">
            <p className="price-container-header">Tarjous</p>
            <div className="proposal-info-item-container">
              <p className="proposal-info-item-header">Tarjouksen päiväys</p>
              <p className="proposal-info-item-text">{moment(this.state.proposal.createdAt).format('MMMM Do YYYY')}</p>
            </div>
            <div className="proposal-info-item-container">
              <p className="proposal-info-item-header">Tarjous voimassa</p>
              <p className="proposal-info-item-text">{moment(this.state.proposal.valid).format('MMMM Do YYYY')}</p>
            </div>
            <div className="proposal-info-item-container">
              <p className="proposal-info-item-header">Tarjouksen laatija</p>
              <p className="proposal-info-item-text">{this.state.proposal.createdBy}</p>
            </div>
          </div>
          <div className="price-container">
            <p className="price-container-header">Hinnasto</p>
            <div className="price-table-container">            
              <table className="table mb-0 table-striped table-hover price-table table-responsive-sm proposal-table">
                <thead className="bg-light">                  
                  <tr>
                    <th scope="col" className="border-0">
                      Kuvaus
                        </th>
                    <th scope="col" className="border-0">
                      Määrä
                        </th>
                    <th scope="col" className="border-0">
                      Yksikkö
                        </th>
                    <th scope="col" className="border-0">
                      Hinta
                        </th>
                    <th scope="col" className="border-0">
                      ALV(24%)
                    </th>
                    <th scope="col" className="border-0">
                      Yhteensä
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.proposal.items.map(item => (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>{item.unit}</td>
                      <td>{item.price / 100}€</td>
                      <td>{(item.price / 100 / 0.76 * 0.24)}€</td>
                      <td>{(item.price / 100 / 0.76)}€</td>                    
                    </tr>
                  ))}                            
                </tbody>
              </table>
            </div>
          </div>
          <div>
            {this.state.proposal.items.map(item => (
              <div className="proposal-item-container">
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">Kuvaus</p>
                  <p className="proposal-item-text">{item.name}</p>
                </div>
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">Määrä</p>
                  <p className="proposal-item-text">{item.amount}</p>
                </div>
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">Yksikkö</p>
                  <p className="proposal-item-text">{item.unit}</p>
                </div>
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">Hinta</p>
                  <p className="proposal-item-text">{(item.price / 100).toFixed(2)}€</p>
                </div>
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">ALV(24%)</p>
                  <p className="proposal-item-text">{(item.price / 100 / 0.76 * 0.24).toFixed(2)}€</p>
                </div>
                <div className="proposal-item-sub-container">
                  <p className="proposal-item-header">Yhteensä</p>
                  <p className="proposal-item-text">{(item.price / 100 / 0.76).toFixed(2)}€</p>
                </div>
              </div>
            ))}
          </div>
          <div className="footer-container">
            <p className="footer-container-header">Sinä vuokraat, me hoidamme loput.</p>
            <div className="footer-company-info-container">
              <p className="footer-container-company-info-text"><i class="material-icons">home</i>2531350-5</p>
              <p className="footer-container-company-info-text"><i class="material-icons">mail</i>info@cleanday.io</p>
              <p className="footer-container-company-info-text"><i class="material-icons">phone</i>+358 46 963 0138</p>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default BlogPosts;
