/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormInput,
  FormGroup,
  Badge,
  Button,
  Modal, ModalBody, ModalHeader
} from "shards-react";

import { Store } from "../flux";

import PageTitle from "../components/common/PageTitle";

class Properties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: Store.getProperties(),    
      user: Store.getUser(),    
      createModalOpen: false
    };

    this.onChange = this.onChange.bind(this);
  }
 
  componentWillMount() {    
    Store.addChangeListener(this.onChange);
    Store.fetchProperties();
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
  
  openCreateModal = () => {
    this.setState({
      createModalOpen: !this.state.createModalOpen
    });
  }

  onChange() {
    this.setState({
      ...this.state,
      properties: Store.getProperties(),
      user: Store.getUser(), 
    });    
  }

  render() {
    const {
      properties
    } = this.state;

    console.log(properties);

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4 justify-content-between align-items-center">
          <PageTitle sm="4" title="Properties" subtitle="Available" className="text-sm-left" />
          {this.state.user.role === 'owner' && <Button theme="primary" className="mb-2 mr-1" onClick={this.openCreateModal}>
            Add New Property
          </Button>}
        </Row>

        {/* Add Ne Plan Button */}
        <Row noGutters className="page-header py-4 text-right">
         
        </Row>
        {/* First Row of Posts */}
        <Row>
          {properties.map((property, idx) => (
            <Col lg="12" md="12" sm="12" className="mb-4" key={property.id}>
              <Card small className="card-post card-post--1" >
                <CardBody>
                  <Badge
                    pill
                    className={`card-post__category bg-${property.id}`}
                  >
                    {'Active'}
                  </Badge>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {property.name}
                    </a>
                  </h5>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <div>
          <Modal open={this.state.createModalOpen} toggle={this.openCreateModal} centered>
            <ModalBody>
              <h4 className="landing-create-header">
                Add Listing
              </h4>
              <Form onSubmit={this.createAccount}>
                <label htmlFor="#name" className="landing-create-label">Listing Name:</label>
                <FormInput id="#name" placeholder="Listing Name" size="lg" className="landing-request-input" onChange={this.setNewEmail} type="text" />
                <br />
                <label htmlFor="#email" className="landing-create-label">Airbnb Calendar ICAL Link:</label>
                <FormInput type="text" id="#email" placeholder="Airbnb ICAL Link" size="lg" className="landing-request-input" onChange={this.setNewPassword} />
                <br />
                <div className="landing-create-button-container">
                  <Button pill className="landing-create-button" size="lg" type="submit">Add Listing</Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </Container>
    );
  }
}

export default Properties;
