import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, Form, CardHeader, Badge, FormTextarea, CardBody, FormInput, Modal, ModalBody, FormSelect, ModalHeader, ButtonGroup, Button } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import CurrentCleaningStats from "./../components/common/CurrentCleaningStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import NewDraft from "./../components/blog/NewDraft";
import Discussions from "./../components/blog/Discussions";
import TopReferrals from "./../components/common/TopReferrals";

import { Store } from "../flux";

import * as moment from 'moment';
import Calendar from 'react-calendar'


import { BarLoader } from 'react-spinners';


import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import './style.css';

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: Store.getStatistics(),
      fetchingCleanings: Store.isEventsFetching(),
      fetchingKeyServices: Store.isKeyServicesFetching(),
      newCleaningDialogOpen: false,
      cleaningRangeFocus: false,
      cleaningsRangeStart: moment(),
      cleaningsRangeEnd: moment().endOf('week'),
      keyServiceRangeFocus: false,
      keyServiceRangeStart: moment(),
      keyServiceRangeEnd: moment().endOf('week'),
      cleanings: Store.getEvents(),
      keyServices: Store.getKeyServices(),
      properties: Store.getProperties(),
      newCleaning_date: moment(),
      newCleaning_cleaning_time: 2,
      newCleaning_guests_amount: 2,
      newCleaning_property: null,
      newCleaning_description: '',
      newKeyServiceDialogOpen: false,
      newKeyService_date: moment(),
      newKeyService_cleaning_time: '15:00',
      newKeyService_property: null,
      newKeyService_description: '',
      defStats: {
        label: "Posts",
        value: "2,390",
        percentage: "4.7%",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: [1, 2, 1, 3, 5, 4, 7]
          }
        ]
      },
    };


    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    //Store.fetchStatistics();
    Store.fetchProperties();
    Store.fetchEvents(moment(), moment().endOf('week'));
    Store.fetchKeyServices(moment(), moment().endOf('week'));
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      cleanings: Store.getEvents(),
      keyServices: Store.getKeyServices(),
      properties: Store.getProperties(),
      fetchingCleanings: Store.isEventsFetching(),
      fetchingKeyServices: Store.isKeyServicesFetching(),
    });
    console.log(this.state);
  }

  openNewCleaningDialog = () => {
    this.setState({
      newCleaningDialogOpen: true
    });
  }
  closeNewCleaningDialog = () => {
    this.setState({
      newCleaningDialogOpen: false
    });
  }
  openNewKeyServiceDialog = () => {
    this.setState({
      newKeyServiceDialogOpen: true
    });
  }
  closeNewKeyServdeDialog = () => {
    this.setState({
      newKeyServiceDialogOpen: false
    });
  }

  getStyleByStatus = (status) => {
    switch (status) {
      case 'pending':
        return {
          style: 'warning',
          text: 'Approve',
          statusText: 'Vireillä',
          nextStatus: 'approved'
        }
        break;
      case 'approved':
        return {
          style: 'primary',
          text: 'Start',
          statusText: 'Vastaanotettu',
          nextStatus: 'started'
        }
        break;
      case 'started':
        return {
          style: 'success',
          statusText: 'Käynnissä',
          text: 'Done',
          nextStatus: 'done'
        }
        break;
      case 'done':
        return {
          style: 'secondary',
          statusText: 'Suoritettu',
          text: 'Done',
          nextStatus: 'pending'
        }
        break;
      default:
      // code block
    }
  }

  createCleaning = (event) => {
    event.preventDefault();
    Store.createCleaning(
      this.state.newCleaning_date,
      this.state.newCleaning_cleaning_time,
      this.state.newCleaning_guests_amount,
      this.state.newCleaning_property,
      this.state.newCleaning_description,
      (status) => {
        if (status) {
          this.setState({
            newCleaningDialogOpen: false
          });
          Store.fetchEvents(moment(this.state.cleaningsRangeStart).format(), moment(this.state.cleaningsRangeEnd).format());

        } else {
          alert('Error creating event');
        }
      }
    )
  }

  setCleaningRange = (sDate, eDate) => {
    this.setState({ cleaningsRangeStart: sDate, cleaningsRangeEnd: eDate })
    Store.fetchEvents(moment(sDate).format(), moment(eDate).format());
  }

  setKeyServiceRange = (sDate, eDate) => {
    this.setState({ keyServiceRangeStart: sDate, keyServiceRangeEnd: eDate })
    Store.fetchKeyServices(moment(sDate).format(), moment(eDate).format());
  }

  selectProperty = (event) => {
    this.setState({ newCleaning_property: event.target.value })
  }
  selectPropertyKey = (event) => {
    this.setState({ newKeyService_property: event.target.value })
  }

  createKeyService = (event) => {
    event.preventDefault();
    Store.createKeyService(
      this.state.newKeyService_date,
      this.state.newKeyService_cleaning_time,
      this.state.newKeyService_property,
      this.state.newKeyService_description,
      (status) => {
        if (status) {
          this.setState({
            newKeyServiceDialogOpen: false
          });
          Store.fetchKeyServices(moment(this.state.cleaningsRangeStart).format(), moment(this.state.cleaningsRangeEnd).format());

        } else {
          alert('Error creating event');
        }
      }
    )
  }

  render() {

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="" subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>

        {/* Current Cleaningsr */}
        {this.state.stats.currentCleanings > 0 &&
          <Row noGutters className="page-header py-4">
            <h6 className="text-sm-left mb-3">
              Current Cleanings
            </h6>
          </Row>
        }
        <Row>
          <Col className="col-lg mb-4" xs="12" sm="12" md="12" lg="3">
            <Card small className="col-lg mb-4">
              <CardHeader>
                <div className="dashboard-card-header">
                  <p className="stats-small__label text-uppercase dashboard-card-header-text">My Apartment</p>
                  {1 == 2 && <i className="material-icons dashboard-card-header-icon" title="Add New">add_circle</i>}
                </div>
              </CardHeader>
              <CardBody>
                {this.state.properties.map(property => (
                  <div>
                    <p className="dashboard-property-name-text">{property.name}</p>
                    <p className="dashboard-property-name-location">{property.name}</p>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg mb-4" xs="12" sm="6" md="6" lg="6" xl="6">
            <Card small className="col-lg mb-4">
              <CardHeader>
                <div className="dashboard-card-header">
                  <p className="stats-small__label text-uppercase dashboard-card-header-text">Cleanings This Week</p>
                  <i className="material-icons dashboard-card-header-icon" title="Add New" onClick={this.openNewCleaningDialog} >add_circle</i>
                </div>
              </CardHeader>
              <CardBody>
                {1 == 2 && <div className="dashboard-calendar-container">
                  <DateRangePicker
                    block
                    startDate={this.state.cleaningsRangeStart} // momentPropTypes.momentObj or null,
                    startDateId="879768768678" // PropTypes.string.isRequired,
                    endDate={this.state.cleaningsRangeEnd} // momentPropTypes.momentObj or null,
                    endDateId="213213422434" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setCleaningRange(startDate, endDate)} // PropTypes.func.isRequired,
                    focusedInput={this.state.cleaningRangeFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ cleaningRangeFocus: focusedInput })} // PropTypes.func.isRequired,
                    firstDayOfWeek={1}
                    hideKeyboardShortcutsPanel
                    displayFormat="DD/MM/YYYY"
                  />
                </div>}
                {this.state.cleanings.length > 0 ?
                  <div className="dashboard-card-list-container">
                    {this.state.cleanings.map((cleaning, index) => (
                      <div className="dashboard-card-cleaning-container">
                        <div>
                          <p className="dashboard-card-cleaning-date"> {moment(cleaning.date).format('dddd, MMMM Do YYYY')}</p>
                          <p className="dashboard-card-cleaning-apartment"><p className="card-text d-inline-block mb-3" >{cleaning.property.name}</p>  </p>
                        </div>
                        <div>
                          <Badge pill theme={this.getStyleByStatus(cleaning.status).style} className="dashboard-card-cleaning-status">
                            {this.getStyleByStatus(cleaning.status).statusText}
                          </Badge>
                        </div>
                      </div>
                    ))}
                    <div className="dashboard-viewall-container">
                      <span className="dashboard-viewall-text" onClick={() => {this.props.history.push('/cleanings')}}>
                        View All
                        </span>
                    </div>
                  </div>
                  :
                  <div className="dashboard-card-calendar-loader-container">
                    {this.state.fetchingCleanings
                      ?
                      <BarLoader />
                      :
                      <span className="dashboard-no-events-this-week-text">No cleanings this week</span>
                    }
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
          <Col className="col-lg mb-4" xs="12" sm="6" md="6" lg="6" xl="6">
            <Card small className="col-lg mb-4">
              <CardHeader>
                <div className="dashboard-card-header">
                  <p className="stats-small__label text-uppercase dashboard-card-header-text">Key Services This Week</p>
                  <i className="material-icons dashboard-card-header-icon" title="Add New" onClick={this.openNewKeyServiceDialog}>add_circle</i>
                </div>
              </CardHeader>
              <CardBody>
                {1 == 2 && <div className="dashboard-calendar-container">
                  <DateRangePicker
                    block
                    startDate={this.state.keyServiceRangeStart} // momentPropTypes.momentObj or null,
                    startDateId="879768sdad768678" // PropTypes.string.isRequired,
                    endDate={this.state.keyServiceRangeEnd} // momentPropTypes.momentObj or null,
                    endDateId="213sada213422434" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setKeyServiceRange(startDate, endDate)} // PropTypes.func.isRequired,
                    focusedInput={this.state.keyServiceRangeFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ keyServiceRangeFocus: focusedInput })} // PropTypes.func.isRequired,
                    firstDayOfWeek={1}
                    hideKeyboardShortcutsPanel
                    displayFormat="DD/MM/YYYY"
                  />
                </div>}
                {!this.state.fetchingCleanings && this.state.cleanings.length > 0 ?
                  <div className="dashboard-card-list-container">
                    {this.state.keyServices.map((cleaning, index) => (
                      <div className="dashboard-card-cleaning-container">
                        <div>
                          <p className="dashboard-card-cleaning-date"> {moment(cleaning.date).format('dddd, MMMM Do YYYY')}</p>
                          <p className="dashboard-card-cleaning-apartment"><p className="card-text d-inline-block mb-3" >{cleaning.property.name}</p>  </p>
                        </div>
                        <div>
                          <Badge pill theme={this.getStyleByStatus(cleaning.status).style} className="dashboard-card-cleaning-status">
                            {this.getStyleByStatus(cleaning.status).statusText}
                          </Badge>
                        </div>
                      </div>
                    ))}
                    <div className="dashboard-viewall-container">
                      <span className="dashboard-viewall-text" onClick={() => {this.props.history.push('/keys')}}>
                        View All
                        </span>
                    </div>
                  </div>
                  :
                  <div className="dashboard-card-calendar-loader-container">
                    {this.state.fetchingKeyServices
                      ?
                      <BarLoader />
                      :
                      <span className="dashboard-no-events-this-week-text">No key services this week</span>
                    }
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        {1 == 2 && <Row>
          {this.state.stats.currentCleanings.map(curCleaning => (
            <Col className="col-lg mb-4" xs="12" key={curCleaning.id}>
              <CurrentCleaningStats
                id={`small-stats-${curCleaning.id}`}
                variation="1"
                chartData={this.state.defStats.datasets}
                chartLabels={this.state.defStats.chartLabels}
                label={'Current Cleaning'}
                location={curCleaning.location}
                cleaner={curCleaning.cleaner.name}
                value={curCleaning.started_at}
                percentage={Math.round(this.state.stats.doneCleanings.totalDuration / 1000 / 60 / 60 * 100) / 100}
                increase={this.state.defStats.increase}
                decrease={this.state.defStats.decrease}
              />
            </Col>
          ))}
        </Row>}
        {/* Stats */}
        {1 == 2 && <Row noGutters className="page-header py-4">
          <h6 className="text-sm-left mb-3">
            Statistics
          </h6>
        </Row>}
        {1 == 2 && <Row>
          <Col className="col-lg mb-4" xs="12" key={'test'}>
            <SmallStats
              id={`small-stats-${'test'}`}
              variation="1"
              chartData={this.state.defStats.datasets}
              chartLabels={this.state.defStats.chartLabels}
              label={'Total Time'}
              value={moment.duration(this.state.stats.doneCleanings.totalDuration).humanize()}
              percentage={Math.round(this.state.stats.doneCleanings.totalDuration / 1000 / 60 / 60 * 100) / 100}
              increase={this.state.defStats.increase}
              decrease={this.state.defStats.decrease}
            />
          </Col>
          {1 == 2 && <Col className="col-lg mb-4" key={'test'}>
            <SmallStats
              id={`small-stats-${'test'}`}
              variation="1"
              chartData={this.state.defStats.datasets}
              chartLabels={this.state.defStats.chartLabels}
              label={'Cleanings Done'}
              value={this.state.stats.doneCleanings.cleanings.length}
              percentage={Math.round(this.state.stats.doneCleanings.totalDuration / 1000 / 60 / 60 * 100) / 100}
              increase={this.state.defStats.increase}
              decrease={this.state.defStats.decrease}
            />
          </Col>}
          {1 == 2 && <Col className="col-lg mb-4" xs="12" key={'test'}>
            <SmallStats
              id={`small-stats-${'test'}`}
              variation="1"
              chartData={this.state.defStats.datasets}
              chartLabels={this.state.defStats.chartLabels}
              label={'Cleanings Coming Up'}
              value={this.state.stats.approvedCleanings.length}
              percentage={Math.round(this.state.stats.doneCleanings.totalDuration / 1000 / 60 / 60 * 100) / 100}
              increase={this.state.defStats.increase}
              decrease={this.state.defStats.decrease}
            />
          </Col>}
          {1 == 2 && this.props.smallStats.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>}

        {1 == 2 && <Row>

          {/* Top Referrals */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <TopReferrals cleanings={this.state.stats.doneCleanings.cleanings} />
          </Col>
        </Row>}
        <Modal open={this.state.newCleaningDialogOpen} toggle={this.closeNewCleaningDialog} backdropClassName="add-cleaning-backdrop" className="add-cleaning-modal">
          <ModalBody>
            <Form onSubmit={this.createCleaning}>
              <div className="add-cleaning-calendar-container">
                <div className="add-cleaning-calendar-header-container">
                  <p className="add-cleaning-calendar-header-text">Päivämäärä</p>
                </div>
                <SingleDatePicker
                  date={this.state.newCleaning_date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ newCleaning_date: date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id" // PropTypes.string.isRequired,
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel
                  displayFormat="DD/MM/YYYY"
                />
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Aika</p>
                  </div>
                  <ButtonGroup className="mb-12 add-cleaning-time-group">
                    <Button theme={this.state.newCleaning_cleaning_time === 1 ? "primary" : "white"} onClick={() => { this.setState({ newCleaning_cleaning_time: 1 }) }}>07:00-12:00</Button>
                    <Button theme={this.state.newCleaning_cleaning_time === 2 ? "primary" : "white"} onClick={() => { this.setState({ newCleaning_cleaning_time: 2 }) }}>12:00-18:00</Button>
                    <Button theme={this.state.newCleaning_cleaning_time === 3 ? "primary" : "white"} onClick={() => { this.setState({ newCleaning_cleaning_time: 3 }) }}>18:00-22:00</Button>
                  </ButtonGroup>
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Vieräiden määrää</p>
                  </div>
                  <FormInput placeholder="2" type="number" value={this.state.newCleaning_guests_amount} onChange={(event) => this.setState({ newCleaning_guests_amount: event.target.value })} />
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Kohde</p>
                  </div>
                  <FormSelect value={this.state.newCleaning_property} onChange={this.selectProperty}>
                    <option value={null}></option>
                    {this.state.properties.map(property => (
                      <option value={property.id}>{property.name}</option>
                    ))}
                  </FormSelect>
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Huomautukset</p>
                  </div>
                  <FormTextarea size="xs" onChange={(event) => this.setState({ newCleaning_description: event.target.value })} value={this.state.newCleaning_description} />
                </div>
              </div>


              <div className="add-cleaning-add-button-container">
                <Button className="add-cleaning-add-button" type="submit">Lisää</Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>



        <Modal open={this.state.newKeyServiceDialogOpen} toggle={this.closeNewKeyServdeDialog} backdropClassName="add-cleaning-backdrop" className="add-cleaning-modal">
          <ModalBody>
            <Form onSubmit={this.createKeyService}>
              <div className="add-cleaning-calendar-container">
                <div className="add-cleaning-calendar-header-container">
                  <p className="add-cleaning-calendar-header-text">Päivämäärä</p>
                </div>
                <SingleDatePicker
                  date={this.state.newKeyService_date} // momentPropTypes.momentObj or null
                  onDateChange={date => this.setState({ newKeyService_date: date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id2" // PropTypes.string.isRequired,
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel
                  displayFormat="DD/MM/YYYY"
                />
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Aika</p>
                  </div>
                  <FormInput placeholder="21:00" type="text" value={this.state.newKeyService_cleaning_time} onChange={(event) => this.setState({ newKeyService_cleaning_time: event.target.value })} />
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Kohde</p>
                  </div>
                  <FormSelect value={this.state.newKeyService_property} onChange={this.selectPropertyKey}>
                    <option value={null}></option>
                    {this.state.properties.map(property => (
                      <option value={property.id}>{property.name}</option>
                    ))}
                  </FormSelect>
                </div>
              </div>

              <div>
                <div className="add-cleaning-time-container">
                  <div className="add-cleaning-time-header-container">
                    <p className="add-cleaning-time-header-text">Huomautukset</p>
                  </div>
                  <FormTextarea size="xs" onChange={(event) => this.setState({ newKeyService_description: event.target.value })} value={this.state.newKeyService_description} />
                </div>
              </div>


              <div className="add-cleaning-add-button-container">
                <Button className="add-cleaning-add-button" type="submit">Lisää</Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

export default Overview;

Overview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

Overview.defaultProps = {
  smallStats: [
    {
      label: "Posts",
      value: "2,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Pages",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
  ]
};