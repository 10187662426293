/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Button,
  Slider,
  Form,
  FormInput,
  Alert,
  FormCheckbox,
  Modal,
  FormRadio,
  ModalBody
} from "shards-react";


import { Store } from "../flux";

import './rovaniemi_style.css';


class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phoneNumber: '',
      name: '',
      newEmail: '',
      newName: '',
      newPassword: '',
      modalOpen: false,
      createModalOpen: false,
      savingValue: 15,
      errors: false,
      errorModal: true,
      contactInformationSubmited: false,
      requestContactSent: false,
      requestContactSentLoading: false,
      plans: Store.getPlans(),
      alertVisible: Store.getBetaAlertStatus(),
      contactInformation: {
        firstName: '',
        firstNameError: false,
        familyName: '',
        familyNameError: false,
        email: '',
        emailError: false,
        phoneNumber: '',
        phoneNumberError: false,
        intrestedIn: [],
        intrestedInError: false,
        agreed: false,
        agreedError: false,
      }
    };

    this.plansRef = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    // Store.fetchPlans();
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      alertVisible: Store.getBetaAlertStatus(),
    });
  }
  scrollToPlans = () => {
    window.scrollTo(0, this.plansRef.current.offsetTop)
  }
  errorModalToggle = () => {
    this.setState({ errorModal: !this.state.errorModal })
  }

  setEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  setPhoneNumber = (event) => {
    this.setState({ phoneNumber: event.target.value });
  }

  setName = (event) => {
    this.setState({ name: event.target.value });
  }

  goToLogin = () => {
    this.props.history.push('/login');
  }

  openModal = () => {
    // this.openCreateModal();
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  openCreateModal = () => {
    this.setState({
      createModalOpen: !this.state.createModalOpen
    });
  }

  handleSlideSavings = (e) => {
    this.setState({
      savingValue: parseFloat(e[0])
    });
  }

  requestContact = (event) => {
    event.preventDefault();
    this.setState({ requestContactSentLoading: true })
    if (this.state.phoneNumber.length > 0) {
      Store.requestBetaAccess('Contact request', this.state.phoneNumber, (status) => {
        if (status) {
          this.setState({
            modalOpen: false,
            requestContactSent: true,
            requestContactSentLoading: false
          })
        }
      })
    }
  }
  requestContactForm = (event) => {
    event.preventDefault();
    console.log('Submiting')
    this.setState({ errors: 0 })
    let err = 0;
    let keyArray = ['firstName', 'familyName', 'email', 'phoneNumber', 'intrestedIn']
    keyArray.map((information, i) => {
      if (this.state.contactInformation[information].length <= 0) {
        err++;
      }
    });

    if (!this.state.contactInformation.agreed) {
      err++;
    }

    if (err == 0) {
      console.log('no errors');
      this.setState({
        errors: false
      });
      Store.requestContact(this.state.contactInformation, (status) => {
        console.log(status);
        if (status) {
          this.setState({
            contactInformationSubmited: true
          });
          this.contactFormButton.blur();
        } else {
          this.setState({
            errors: true
          });
        }
      })
      this.setState({
        contactInformationSubmited: true
      });
      this.contactFormButton.blur();
    } else {
      console.log('some errors')
      this.setState({
        errors: true
      })
    }
    console.log(err);
  }
  handleChecked = (key) => {
    console.log(this.state.contactInformation.intrestedIn);
    return true;
  }
  handleInterestedIn = (key) => {

    let arr = this.state.contactInformation.intrestedIn;
    console.log(arr);
    if (arr.indexOf(key) == -1) {
      arr.push(key)
      this.setState({
        contactInformation: {
          ...this.state.contactInformation,
          intrestedIn: arr
        }
      })
      console.log(this.state)
    } else {
      let newArr = arr.filter(a => a !== key);
      this.setState({
        contactInformation: {
          ...this.state.contactInformation,
          intrestedIn: newArr
        }
      })

    }

  }

  render() {
    return (
      <div className="rovaniemi-main-container">
        <div className="rovaniemi-nav-container">
          <Alert open={this.state.alertVisible} className="rovaniemi-alert">
            Kiitos yhteydenotosta. Palaamme asiaan pikaisesti!
          </Alert>
        </div>
        <div className="rovaniemi-top-container">
          <div className="rovaniemi-top-container-phone-text-container">
            <p className="rovaniemi-top-container-phone-text-sub">HELSINKI – ROVANIEMI</p>
            <h1 className="rovaniemi-top-container-phone-text-header">Ammattimaiset siivouspalvelut</h1>

            {1 == 2 && <p className="rovaniemi-top-container-you-text">Sinä vuokraat, me hoidamme loput.</p>}

            <p className="rovaniemi-top-container-you-text">Desinfioiva kotisiivous <span style={{ textDecoration: 'underline' }}>alkaen 40€</span></p>

            {1 == 2 && <div className="rovaniemi-prop-list-container">
              <div className="rovaniemi-prop-list-item-container">
                <i class="material-icons">check</i>
                <p className="rovaniemi-prop-list-item-text">Ammattitaitoiset kiinteistönhoitajat ja siivoojat</p>
              </div>
              <div className="rovaniemi-prop-list-item-container">
                <i class="material-icons">check</i>
                <p className="rovaniemi-prop-list-item-text">Laadukas asiakaspalvelu</p>
              </div>
              <div className="rovaniemi-prop-list-item-container">
                <i class="material-icons">check</i>
                <p className="rovaniemi-prop-list-item-text">Edullinen hinta</p>
              </div>
              <div className="rovaniemi-prop-list-item-container">
                <i class="material-icons">check</i>
                <p className="rovaniemi-prop-list-item-text">Ei vuokrauksia, ei kuluja</p>
              </div>
            </div>}
            {1 == 2 && <div className="rovaniemi-phone-number-container">
              <p className="rovaniemi-phone-number-text">info@cleanday.io</p>
            </div>}
            <div className="rovaniemi-buttons-container">
              <div className="rovaniemi-beta-button-container" onClick={this.scrollToPlans}>
                <p className="rovaniemi-beta-button-text">Ota Yhteyttä</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="rovaniemi-call-container">
          <div className="rovaniemi-feature-container-call">
            <p className="rovaniemi-feature-container-call-text">Jätä soittopyyntö asiantuntijallemme</p>
            <div className="rovaniemi-call-container-input-container">
              <FormInput placeholder="esim. +358 12 123 4567" className="rovaniemi-call-container-input-field" onChange={this.setPhoneNumber} />
              <div className={this.state.requestContactSent ? "rovaniemi-beta-button-container sent" : "rovaniemi-beta-button-container sent"} onClick={this.requestContact}>
                {!this.state.requestContactSentLoading && <p className="rovaniemi-call-button-text">Lähetä</p>}
                {this.state.requestContactSentLoading && 
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                }
              </div>
            </div>
          </div>
        </div> */}


        {1 == 2 && <div className="rovaniemi-feature-container">
          <div className="rovaniemi-service-container">
            <h3 className="rovaniemi-feedback-container-text-header">94% majoittuneista vieraista kertoivat, että kohde oli hohtavan puhdas</h3>
            <div className="rovaniemi-feedbacks-container">
              <div className="rovaniemi-feedback-container">
                <div className="rovaniemi-feedback-avatar">
                  <p className="rovaniemi-feedback-avatar-text">R</p>
                </div>
                <div className="rovaniemi-feedback-text-container">
                  <p className="rovaniemi-feedback-text-container-text">Clean and beautiful apartment.</p>
                </div>
              </div>
              <div className="rovaniemi-feedback-container">
                <div className="rovaniemi-feedback-text-container">
                  <p className="rovaniemi-feedback-text-container-text">... and the place was perfectly clean and neat.</p>
                </div>
                <div className="rovaniemi-feedback-avatar-right">
                  <p className="rovaniemi-feedback-avatar-text">S</p>
                </div>
              </div>
              <div className="rovaniemi-feedback-container">
                <div className="rovaniemi-feedback-avatar-second">
                  <p className="rovaniemi-feedback-avatar-text">J</p>
                </div>
                <div className="rovaniemi-feedback-text-container">
                  <p className="rovaniemi-feedback-text-container-text">The flat was great and super clean!</p>
                </div>
              </div>
            </div>
          </div>
        </div>}

        <div className="rovaniemi-feature-container">
          <div className="rovaniemi-service-container">
            <div className="rovaniemi-header-container">
              <h2 className="rovaniemi-header-text">Palvelumme</h2>
              <div className="rovaniemi-header-line" />
            </div>
            <div className="rovaniemi-service-items-container">
              <div className="rovaniemi-feature-service-item-container one">
                <h3 className="rovaniemi-feature-service-item-container-text-header">Toimistosiivous</h3>
                <div className="rovaniemi-feature-service-item-container-text-sub">

                  <p>
                    Teemme toimistostasi siisti ja inspiroiva työympäristö.  Loistava työpäivä alkaa aamulla puhtaalta pöydältä. Siisti ja puhdas toimisto lisää henkilökunnan ja asiakkaiden viihtyvyyttä.
                    </p>
                </div>
                <div className="rovaniemi-feature-service-item-container-text-header-price-container">
                  <p className="rovaniemi-feature-service-item-container-text-header-price-reduced">25€ / tunti</p>
                  <p className="rovaniemi-feature-service-item-container-text-header-price-normal">(sis. ALV)</p>
                </div>
              </div>
              <div className="rovaniemi-feature-service-item-container two">
                <h3 className="rovaniemi-feature-service-item-container-text-header">Airbnb siivous </h3>
                <p className="rovaniemi-feature-service-item-container-text-sub">Airbnb asunnon puhtaus on ensiarvoisen tärkeää. Tarjoamme räätälöityjä siivouspalveluita, jotka joustavat sinun tarpeidesi mukaan.</p>
                <div className="rovaniemi-feature-service-item-container-text-header-price-container">
                  <p className="rovaniemi-feature-service-item-container-text-header-price-reduced">30€ / tunti</p>
                  <p className="rovaniemi-feature-service-item-container-text-header-price-normal">(sis. ALV)</p>
                </div>
              </div>
              <div className="rovaniemi-feature-service-item-container two">
                <h3 className="rovaniemi-feature-service-item-container-text-header">DESINFIOIVA SIIVOUS</h3>
                <p className="rovaniemi-feature-service-item-container-text-sub">Viranomaisohjeiden mukainen desinfioiva siivous on tärkeämpää kuin koskaan.</p>
                <div className="rovaniemi-feature-service-item-container-text-header-price-container">
                  <p className="rovaniemi-feature-service-item-container-text-header-price-normal">alkaen</p>
                  <p className="rovaniemi-feature-service-item-container-text-header-price-reduced">40€ / tunti</p>
                  <p className="rovaniemi-feature-service-item-container-text-header-price-normal">(sis. ALV)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rovaniemi-contact-container" ref={this.plansRef}>
          <div className="rovaniemi-header-container">
            <h2 className="rovaniemi-header-text">Ota Yhteyttä</h2>
            <div className="rovaniemi-header-line" />
          </div>
          <form onSubmit={this.requestContactForm}>
            <div className="rovaniemi-contact-information-container">
              <div className="rovaniemi-contact-information-container-sub">
                <div className="rovaniemi-contact-information-item-container">
                  <label className="rovaniemi-contact-information-item-label-text">Etunimi</label>
                  <FormInput invalid={this.state.contactInformation.firstNameError} value={this.state.contactInformation.firstName} placeholder="Etunimi" className="rovaniemi-contact-information-item-input-field" onChange={(event) => {
                    this.setState({
                      contactInformation: {
                        ...this.state.contactInformation,
                        firstName: event.target.value,
                        firstNameError: false
                      }
                    })
                  }} />
                </div>
                <div className="rovaniemi-contact-information-item-container">
                  <label className="rovaniemi-contact-information-item-label-text">Sukunimi</label>
                  <FormInput invalid={this.state.contactInformation.familyNameError} value={this.state.contactInformation.familyName} placeholder="Sukunimi" className="rovaniemi-contact-information-item-input-field" onChange={(event) => {
                    this.setState({
                      contactInformation: {
                        ...this.state.contactInformation,
                        familyName: event.target.value,
                      }
                    })
                  }} />
                </div>
              </div>
              <div className="rovaniemi-contact-information-container-sub">
                <div className="rovaniemi-contact-information-item-container">
                  <label className="rovaniemi-contact-information-item-label-text">Sähköpostiosoite</label>
                  <FormInput invalid={this.state.contactInformation.emailError} value={this.state.contactInformation.email} placeholder="Sähköpostiosoite" className="rovaniemi-contact-information-item-input-field" onChange={(event) => {
                    this.setState({
                      contactInformation: {
                        ...this.state.contactInformation,
                        email: event.target.value,
                      }
                    })
                  }} />
                </div>
                <div className="rovaniemi-contact-information-item-container">
                  <label className="rovaniemi-contact-information-item-label-text">Puhelin</label>
                  <FormInput invalid={this.state.contactInformation.phoneNumberError} value={this.state.contactInformation.phoneNumber} placeholder="Puhelin" className="rovaniemi-contact-information-item-input-field" onChange={(event) => {
                    this.setState({
                      contactInformation: {
                        ...this.state.contactInformation,
                        phoneNumber: event.target.value,
                      }
                    })
                  }} />
                </div>
              </div>
              <div className="rovaniemi-contact-information-container-sub">
                <div className="rovaniemi-contact-information-item-container-services">
                  <label className="rovaniemi-contact-information-item-label-text">Olen kiinnostunut</label>
                  <div className="rovaniemi-contact-information-item-services">
                    <FormRadio
                      className="rovaniemi-contact-information-item-service-select"
                      name="cleaning"
                      checked={this.state.contactInformation.intrestedIn.indexOf('cleaning') > -1}
                      onChange={() => {
                        this.handleInterestedIn("cleaning");
                      }}
                    >
                      <span className="rovaniemi-contact-information-item-service-select" >Toimistosiivous</span>
                    </FormRadio>
                    <FormRadio
                      name="cleaning_extended"
                      checked={this.state.contactInformation.intrestedIn.indexOf('cleaning_extended') > -1}
                      onChange={() => {
                        this.handleInterestedIn("cleaning_extended");
                      }}
                    >
                      <span className="rovaniemi-contact-information-item-service-select" >Airbnb siivous</span>
                    </FormRadio>
                    <FormRadio
                      className="rovaniemi-contact-information-item-service-select"
                      name="key_service"
                      checked={this.state.contactInformation.intrestedIn.indexOf('key_service') > -1}
                      onChange={() => {
                        console.log("key_service")
                        this.handleInterestedIn("key_service");
                      }}
                    >
                      <span className="rovaniemi-contact-information-item-service-select" >Desinfioiva siivous</span>
                    </FormRadio>
                  </div>
                </div>
              </div>
              <div className="rovaniemi-contact-information-container-sub">
                <div className="rovaniemi-contact-information-item-container">
                  <FormCheckbox
                    checked={this.state.contactInformation.agreed}
                    invalid={this.state.contactInformation.agreedError}
                    onChange={e => this.setState({
                      contactInformation: {
                        ...this.state.contactInformation,
                        agreed: !this.state.contactInformation.agreed
                      }
                    })}
                  >
                    <span className="rovaniemi-contact-information-agree-text">Cleanday saa lähettää minulle sähköpostitse tietoa tuotteista, palveluista ja tapahtumista, sekä muuta markkinoinnin sisältöä.</span>
                  </FormCheckbox>
                </div>
              </div>
              {this.state.errors && <div className="rovaniemi-contact-information-error-container">
                <span className="rovaniemi-contact-information-error-text">Täytä kaikki kentät, jotta lomake lähtee</span>
              </div>}
              <div className="rovaniemi-contact-information-container-sub">
                <button ref={ref => this.contactFormButton = ref} type="submit" className={!this.state.contactInformationSubmited ? "rovaniemi-contact-information-submit-button-container" : "rovaniemi-contact-information-submit-button-container disabled"}>
                  <span className="rovaniemi-contact-information-submit-button-text">
                    {!this.state.contactInformationSubmited ? 'Lähetä' : 'Lähetetty'}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <br /><br /><br /><br />

        {1 == 2 && <div className="rovaniemi-test-container">
          <h1 className="rovaniemi-test-container-text-header">Testaa kuinka paljon sinä säästäisit jos käyttäisit meidän siivouspalveluamme?</h1>
          <div className="rovaniemi-test-container-use">
            <div className="rovaniemi-test-container-sliders">
              <p className="rovaniemi-slider-header">Siivoukset kuukaudessa</p>
              <Slider
                connect={[true, false]}
                start={[this.state.savingValue]}
                range={{ min: 0, max: 30 }}
                step={5}
                pips={{ mode: "steps", stepped: true, density: 30 }}
                onSlide={this.handleSlideSavings}
              />

            </div>
            <div className="rovaniemi-test-container-sum">
              <p className="rovaniemi-test-container-sum-text">{this.state.savingValue * 40}€ / kk</p>
            </div>
          </div>
        </div>}


        <div>
          <Modal open={this.state.modalOpen} toggle={this.openModal} centered>
            <ModalBody>
              <h5 className="rovaniemi-request-header">
                Request Beta Access
            </h5>
              <Form onSubmit={this.requestBetaAccess}>
                <label htmlFor="#name" className="rovaniemi-request-label">Your Name</label>
                <FormInput id="#name" placeholder="Full Name" className="rovaniemi-request-input" onChange={this.setName} type="text" />
                <br />
                <label htmlFor="#email" className="rovaniemi-request-label">Email</label>
                <FormInput type="email" id="#email" placeholder="Email" className="rovaniemi-request-input" onChange={this.setEmail} />
                <div className="rovaniemi-request-button-container">
                  <Button pill className="rovaniemi-request-button" type="submit">Send</Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default BlogPosts;
