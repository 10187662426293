export default function(role='cleaner') {
  let generalItems = [    
    // {
    //   title: "My Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile",
    // },
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Cleanings",
      htmlBefore: '<i class="material-icons">event</i>',
      to: "/cleanings",
    },
    {
      title: "Key Services",
      htmlBefore: '<i class="material-icons">vpn_key</i>',
      to: "/keys",
    },   
  ];
  let ownerItems = [  
  ];
  let adminItems = [   
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/users",
    },
    {
      title: "Properties",
      htmlBefore: '<i class="material-icons">home</i>',
      to: "/properties",
    },
  ];

  let combinedItems; 

  if (role == 'cleaner') {
    combinedItems = generalItems;    
  } else if (role == 'owner') {
    combinedItems = generalItems.concat(ownerItems);    
  } else if (role == 'admin') {
    combinedItems = generalItems.concat(adminItems);    
  } else {
    combinedItems = [];
  }

  return combinedItems;

  // return [
  //   {
  //     title: "Dashboard",
  //     to: "/dashboard",
  //     htmlBefore: '<i class="material-icons">dashboard</i>',
  //     htmlAfter: ""
  //   },
  //   // {
  //   //   title: "My Profile",
  //   //   htmlBefore: '<i class="material-icons">person</i>',
  //   //   to: "/user-profile",
  //   // },
  //   {
  //     title: "Cleaning",
  //     htmlBefore: '<i class="material-icons">event</i>',
  //     to: "/cleanings",
  //   },
  //   {
  //     title: "Key Service",
  //     htmlBefore: '<i class="material-icons">vpn_key</i>',
  //     to: "/key",
  //   },
  //   {
  //     title: "Users",
  //     htmlBefore: '<i class="material-icons">people</i>',
  //     to: "/users",
  //   },
  //   {
  //     title: "Properties",
  //     htmlBefore: '<i class="material-icons">home</i>',
  //     to: "/properties",
  //   },
  //   // {
  //   //   title: "Forms & Components",
  //   //   htmlBefore: '<i class="material-icons">view_module</i>',
  //   //   to: "/components-overview",
  //   // },
  //   // {
  //   //   title: "Tables",
  //   //   htmlBefore: '<i class="material-icons">table_chart</i>',
  //   //   to: "/tables",
  //   // },    
  //   // {
  //   //   title: "Errors",
  //   //   htmlBefore: '<i class="material-icons">error</i>',
  //   //   to: "/errors",
  //   // }
  // ];

}
