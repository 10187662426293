import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

import { Store } from "../../../../flux";


import Avatar from '../../../../images/avataaars.png'

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      user: Store.getUser(),
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }


  componentWillMount() {
    Store.addChangeListener(this.onChange);    
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
  onChange = () => {
    this.setState({
      ...this.state,
      user: Store.getUser(),
    });
    console.log(this.state);
  }
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    console.log(this.state.user.name)
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle tag={NavLink} className="text-nowrap px-3">
          <div style={{height: 40, width: 40, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9c9c9'}}>
            <span style={{color: '#fff', fontSize: 20}}>{typeof this.state.user.name !== 'undefined' && this.state.user.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}</span>
          </div>          
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/login" className="text-danger" onClick={() => localStorage.clear()}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
